

.compositePrimaryContainer {
    position: relative;
    margin-top: 10px;
    margin-left: 5px;
    border: 1px solid #9eb7bd;
    border-radius: 5px;
    padding-right: 10px;
}


.compositeSecondaryContainer {
    padding: 0 5px 5px 5px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    text-align: left;
    flex-direction: row;
    margin-bottom: 15px;
    font-size: .8rem;
    margin-left: 10px;
    margin-right: 10px;
    color: #494949;
}

.compositeSecondaryContainer .translationContainer {
    border: 1px solid #ccc;
    border-top: none;
    padding: 1px;
    margin-right: 2px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 20px;
    max-height: 22px !important;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}


.translationTab {
    padding: 1px;
    margin-right: 2px;
    flex-direction: row;
    justify-content: space-between;
}
