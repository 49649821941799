.homeLogoContainer {
    margin-top: 40px;
    width: 100%;
}

.homeContentContainer {
    width: 100%;
    display: flex;

    flex-direction: column;
    height: 90vh;
}

.languageBox {
    width: 160px;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

}
